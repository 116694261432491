import Layout from "../../components/layouts/layout";
import Link from "next/link";
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function Custom404() {
  const router = useRouter()
  useEffect(()=>{
    setTimeout(()=>router.push('/'), 3000)
  }, [])
  return (
    <Layout logoType="big">
      <div style={{paddingTop: '25vh', minHeight: '50vh', textAlign: 'center'}}>
        <p style={{fontSize: '25px', fontWeight: 'bold'}}>404 - Page Not Found</p>
        <p><Link href="/">Will be redirect to homepage</Link></p>
      </div>
    </Layout>
  )
}